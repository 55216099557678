import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom'
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MdDeleteOutline } from "react-icons/md";

const Organization = () => {

  const [toast, addToast] = useState(0)
  const navigate = useNavigate()

  const [organization, setOrganization] = useState({})
  const [users, setUsers] = useState([])
  const [businessUnits, setBusinessUnits] = useState([])
  const [assetGroups, setAssetGroups] = useState([])
  const [onLoading, setOnLoading] = useState(false);
  const [currentlySelectedJob, setCurrentlySelectedJob] = useState(null)

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const toaster = useRef()
  const exampleToast = (
    <CToast>
      <CToastBody>Success</CToastBody>
    </CToast>
   )         
    
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };  


  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  

  useEffect(() => {

    window.scrollTo(0, 0);

    loadOrganizationDetails();

  }, []);

  useEffect(() => {
    // console.log('onLoading', onLoading)

  }, [onLoading]);


  const loadOrganizationDetails = async (theScanId) => {

    setOnLoading(true);

    const data = {
      scanId: theScanId,
    };

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/organizations/getOrganizationDetails/', {
      headers: { Authorization: `Bearer ${token}` },
    });

    setOrganization(response.data.organization);

    setUsers(response.data.organization.users);
    setBusinessUnits(response.data.organization.businessUnits);
    setAssetGroups(response.data.organization.assetGroups);

    setOnLoading(false);

  };

  const usersColumns = [
    '',
    "First Name",
    "Last Name",
    "Email",
    "Role",
    "Status",
    "Created At",
    "Updated At", 
    
    {
      label: "Actions",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<
            div style={{
              display: "flex",
              alignItems: "center"
            }
            } >

            <CButton color="info"
            variant="outline"
            className="m-1"
            style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red', display:'flex', flexDirection:'row',
             alignItems:'center', justifyContent:'center' }}>
            
            <MdDeleteOutline style={{color:'red', marginRight:5}} size={15}/>Edit</CButton>

            <CButton color="danger"
              variant="outline"
              className="m-1"
              style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red', display:'flex', flexDirection:'row',
               alignItems:'center', justifyContent:'center' }}>
              
              <MdDeleteOutline style={{color:'red', marginRight:5}} size={15}/>Delete</CButton>
          </div>
          )
        }
      }
    },

  ];

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },

    }
  })


  const usersOptions = {
    filterType: "dropdown",
    responsive: "stacked",
    elevation: 0, //for table shadow box
    filter: true,
    download: true,
    print: true,
    search: true,
    searchOpen: true,
    viewColumns: true,
    selectableRows: false, // <===== will turn off checkboxes in rows
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    pagination: false,
    textLabels: {
      body: {
        noMatch: 'No users created yet',
      }
    }
  };


  var usersTableData = [];


  for (var i = 0; i < users.length; i++) {


    var dataItem = [];

    dataItem.push(i+1);
    dataItem.push(users[i].firstName);
    dataItem.push(users[i].lastName);
    dataItem.push(users[i].email);
    dataItem.push(users[i].role);
    dataItem.push(users[i].status);

    dataItem.push((new Date(users[i].createdAt)).toLocaleDateString('en-US') + ' - ' + (new Date(users[i].createdAt)).toLocaleTimeString('en-US'));
    dataItem.push((new Date(users[i].updatedAt)).toLocaleDateString('en-US') + ' - ' + (new Date(users[i].updatedAt)).toLocaleTimeString('en-US'));  


    dataItem.push(users[i]._id); // for delete

    usersTableData.push(dataItem);
  }

  // Business units table
  const businessUnitsColumns = [
    '',
    "Name",    
    "Created At",
    "Updated At",     
    {
      label: "Actions",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<
            div style={{
              display: "flex",
              alignItems: "center"
            }
            } >

            <CButton color="info"
            variant="outline"
            className="m-1"
            style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red', display:'flex', flexDirection:'row',
             alignItems:'center', justifyContent:'center' }}>
            
            <MdDeleteOutline style={{color:'red', marginRight:5}} size={15}/>Edit</CButton>

            <CButton color="danger"
              variant="outline"
              className="m-1"
              style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red', display:'flex', flexDirection:'row',
               alignItems:'center', justifyContent:'center' }}>
              
              <MdDeleteOutline style={{color:'red', marginRight:5}} size={15}/>Delete</CButton>
          </div>
          )
        }
      }
    },

  ];

 


  const businessUnitsOptions = {
    filterType: "dropdown",
    responsive: "stacked",
    elevation: 0, //for table shadow box
    filter: true,
    download: true,
    print: true,
    search: true,
    searchOpen: true,
    viewColumns: true,
    selectableRows: false, // <===== will turn off checkboxes in rows
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    pagination: false,
    textLabels: {
      body: {
        noMatch: 'No users created yet',
      }
    }
  };


  var businessUnitsTableData = [];


  for (var i = 0; i < businessUnits.length; i++) {


    var dataItem = [];

    dataItem.push(i+1);
    dataItem.push(businessUnits[i].name);
    
    dataItem.push((new Date(businessUnits[i].createdAt)).toLocaleDateString('en-US') + ' - ' + (new Date(businessUnits[i].createdAt)).toLocaleTimeString('en-US'));
    dataItem.push((new Date(businessUnits[i].updatedAt)).toLocaleDateString('en-US') + ' - ' + (new Date(businessUnits[i].updatedAt)).toLocaleTimeString('en-US'));  


    dataItem.push(businessUnits[i]._id); // for delete

    businessUnitsTableData.push(dataItem);
  }
  


  // Asset groups table
  const assetGroupsColumns = [
    '',
    "Name",    
    "Created At",
    "Updated At",     
    {
      label: "Actions",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<
            div style={{
              display: "flex",
              alignItems: "center"
            }
            } >

            <CButton color="info"
            variant="outline"
            className="m-1"
            style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red', display:'flex', flexDirection:'row',
             alignItems:'center', justifyContent:'center' }}>
            
            <MdDeleteOutline style={{color:'red', marginRight:5}} size={15}/>Edit</CButton>

            <CButton color="danger"
              variant="outline"
              className="m-1"
              style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red', display:'flex', flexDirection:'row',
               alignItems:'center', justifyContent:'center' }}>
              
              <MdDeleteOutline style={{color:'red', marginRight:5}} size={15}/>Delete</CButton>
          </div>
          )
        }
      }
    },

  ];

 


  const assetGroupsOptions = {
    filterType: "dropdown",
    responsive: "stacked",
    elevation: 0, //for table shadow box
    filter: true,
    download: true,
    print: true,
    search: true,
    searchOpen: true,
    viewColumns: true,
    selectableRows: false, // <===== will turn off checkboxes in rows
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    pagination: false,
    textLabels: {
      body: {
        noMatch: 'No users created yet',
      }
    }
  };


  var assetGroupsTableData = [];


  for (var i = 0; i < assetGroups.length; i++) {


    var dataItem = [];

    dataItem.push(i+1);
    dataItem.push(assetGroups[i].name);
    
    dataItem.push((new Date(assetGroups[i].createdAt)).toLocaleDateString('en-US') + ' - ' + (new Date(assetGroups[i].createdAt)).toLocaleTimeString('en-US'));
    dataItem.push((new Date(assetGroups[i].updatedAt)).toLocaleDateString('en-US') + ' - ' + (new Date(assetGroups[i].updatedAt)).toLocaleTimeString('en-US'));  


    dataItem.push(assetGroups[i]._id); // for delete

    assetGroupsTableData.push(dataItem);
  }

   return (

    
    <div style={{ overflow: "scroll", position:'relative', overflowY: 'hidden',overflowX: 'hidden',  }}>
    <div style={{ width:'100%'}}>
    <div>
      <div style={{ marginBottom: '2rem', display:'flex', flexDirection:'column', justifyContent:'space-between' }}>

        <h2 style={{color:'#7366ff', fontWeight:'bold'}}>Organization</h2>
        <hr/>

     <div style={{display:'flex', flexDirection:'row', marginBottom:30}}>

        <div style={{width:'60%'}}>  

          <span>Organization Name : <span style={{fontWeight:'bold'}}>{organization.name}</span></span> 

            
        </div>        

      </div>    

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
    <Tab label="Users" {...a11yProps(0)} />
    <Tab label="Business Units" {...a11yProps(1)} />
    <Tab label="Asset Groups" {...a11yProps(2)} />
  </Tabs>
</Box>
<CustomTabPanel value={value} index={0}>

Users

              <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  style={{ height: "57vh" }}
                  data={usersTableData}
                  columns={usersColumns}
                  options={usersOptions}
                />
              </ThemeProvider>


</CustomTabPanel>
<CustomTabPanel value={value} index={1}>
Business Units
<ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  style={{ height: "57vh" }}
                  data={businessUnitsTableData}
                  columns={businessUnitsColumns}
                  options={businessUnitsOptions}
                />
              </ThemeProvider>
</CustomTabPanel>
<CustomTabPanel value={value} index={2}>
Asset Groups
<ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  style={{ height: "57vh" }}
                  data={assetGroupsTableData}
                  columns={assetGroupsColumns}
                  options={assetGroupsOptions}
                />
              </ThemeProvider>
</CustomTabPanel>

  
        
      </div>

</div> 
    </div> 
    </div>     
   )
}

export default Organization
            


